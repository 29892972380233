import $ from 'jquery'

$(document).on('turbolinks:load', function () {
  const metaCsrf = document.querySelector("meta[name='csrf-token']");
  const csrfToken = metaCsrf.getAttribute('content');

  function click_user_action(userID, method) {
    $(".popup-action").hide();
    $("#user-confirm-title").html(`Are you sure to ${method} this user?`);
    $(".user-confirm-confirm").html('YES');
    $("#confirm-modal").val([userID, method]);
    $("#confirm-modal").show();
  }

  // click activate button
  $(".stimulus").on("click", ".activate-user", function (e) {
    const userID = this.id.split('-').pop();
    click_user_action(userID, 'activate');
  });

  // click ban button
  $(".stimulus").on("click", ".ban-user", function (e) {
    const userID = this.id.split('-').pop();
    click_user_action(userID, 'ban');
  });

  // click delete button
  $(".stimulus").on("click", ".delete-user", function (e) {
    const userID = this.id.split('-').pop();
    click_user_action(userID, 'delete');
  });

  // click confirm modal discard button
  $(".stimulus").on("click", "#user-confirm-discard", function (e) {
    $("#confirm-modal").hide();
  });

  // click confirm modal confirm button
  $(".stimulus").on("click", ".user-confirm-confirm", function (e) {
    const userID = $("#confirm-modal").val()[0];
    const method = $("#confirm-modal").val()[1];
    $.ajax({
      url: `/users/${userID}/${method}`,
      type: 'PUT',
      headers: {
        'x-csrf-token': csrfToken,
      },
      success: function () {
        $("#confirm-modal").hide();
        $("#complete-modal").show();
      }
    });
  });

  // click popup action backgronud
  $(".stimulus").on("click", ".bg-popup-action-user", function (e) {
    $(".popup-action").hide();
  });

  // click confirm modal background
  $(".stimulus").on("click", ".bg-user-confirm-modal", function (e) {
    $("#confirm-modal").hide();
  });

  // click confirm modal background
  $(".stimulus").on("click", ".content-user-confirm-modal", function (e) {
    e.stopPropagation();
    return;
  });
});
