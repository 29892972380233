import $ from 'jquery';

$(document).on('turbolinks:load', function () {
  // open new asset modal when click button
  $("#new-group").on("click", function () {
    $("#new-group-modal").show();
  });

  // stop close modal when click inside modal content
  $("#content-new-group-modal").on("click", function (e) {
    e.stopPropagation();
    return;
  });

  // close modal
  $("#bg-new-group-modal").on("click", function (e) {
    $("#new-group-modal").hide();
  });
});
