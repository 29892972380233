import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["results", "form"];

  connect() {
    this.lastSearchWord = '';
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.lastSearchWord = $(this.formTarget)[0][0].value;
      Rails.fire(this.formTarget, 'submit');
    }, 150);
  }

  handleResults(event) {
    const [data, status, xhr] = event.detail
    const searchWord = event.path[0][0].value;
    if (searchWord != this.lastSearchWord) return;

    this.resultsTarget.innerHTML = xhr.response
  }
}
