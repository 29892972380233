import $ from 'jquery';

const metaCsrf = document.querySelector("meta[name='csrf-token']");
const csrfToken = metaCsrf.getAttribute('content');

function submitPassword() {
  const id = $(".password-submit").attr("id");
  const assetID = id.slice(id.indexOf("-") + 1);
  const searchParams = new URLSearchParams(window.location.search)
  let path = `/assets/${assetID}`
  if (searchParams.get('transfer_link_uuid')) {
    path = `/guests/${assetID}?transfer_link_uuid=${searchParams.get('transfer_link_uuid')}`
  }
  setTimeout(() => {
    window.location.href = path;
  }, 2000); // delay 2 seconds to return to asset page
}

$(document).on('turbolinks:load', function () {
  $(".password-submit").on("click", function (e) {
    submitPassword();
  });

  $(".text-password-download").on("keydown", function (e) {
    if (e.key == "Enter") {
      submitPassword();
    }
  });
});
