import $ from 'jquery';

const metaCsrf = document.querySelector("meta[name='csrf-token']");
const csrfToken = metaCsrf.getAttribute('content');

$(document).on('turbolinks:load', function () {
  // open/close popup
  $(".signout").on("click", function () {
    if ($("#signout-popup").css("display") == 'none') {
      $("#signout-popup").show();
    } else {
      $("#signout-popup").hide();
    }
  });

  // click editable label
  $("#editable-label").on("click", function () {
    $("#edit-label-input").show();
    $(".edit-label-input-text").get(0).focus();
    $("#editable-label").hide();
  });

  function updateAssetName(url, name) {
    const data = {
      name
    };
    $.ajax({
      url,
      type: 'PATCH',
      headers: {
        'x-csrf-token': csrfToken
      },
      data,
      error: () => {
        $("#invalid-new-label").show();
      }
    });
  }

  // stop focus editable label
  $(".edit-label-input-text").on("focusout", function () {
    const elementID = this.id;
    const prefix = "edit-label-input-text-";
    const id = elementID.slice(elementID.indexOf(prefix) + prefix.length);
    const name = this.value;
    if ($(this).hasClass('asset')) {
      updateAssetName(`/assets/${id}`, name);
    } else if ($(this).hasClass('group')) {
      updateAssetName(`/groups/${id}`, name);
    }
  });

  let isPressEnter = false;
  // click enter on editable label
  $(".edit-label-input-text").on("keydown", function (e) {
    if (e.key == "Enter" && !isPressEnter) {
      isPressEnter = true;
      const elementID = this.id;
      const prefix = "edit-label-input-text-";
      const id = elementID.slice(elementID.indexOf(prefix) + prefix.length);
      const name = this.value;
      if ($(this).hasClass('asset')) {
        updateAssetName(`/assets/${id}`, name);
      } else if ($(this).hasClass('group')) {
        updateAssetName(`/groups/${id}`, name);
      }
      e.preventDefault();
    }
  });

  // remove flash msg after 5 seconds
  setTimeout(() => {
    $(".flash").fadeOut(1000);
    setTimeout(() => {
      $(".flash").remove()
    }, 1000);
  }, 4000);

  // flatpickr
  flatpickr('.change-expiry-date', {
    enableTime: true,
    dateFormat: "d M Y, H:i",
    onReady: function (selectedDates, dateStr, instance) {
      const value = instance.element.defaultValue;
      instance._input.value = value;
    },
    onClose: function (selectedDates, dateStr, instance) {
      const className = "change-expiry-date";
      const id = instance.element.parentElement.id;
      const elementID = instance.element.id;
      const type = elementID.slice(elementID.indexOf(className) + className.length + 1);
      const data = {
        [type]: {
          expiry_date: selectedDates[0]
        }
      };
      let url = '/assets/';
      if (type == 'transfer_link') {
        const assetID = id.slice(0, id.lastIndexOf('-'))
        const transferID = id.slice(id.lastIndexOf('-') + 1, );
        url += assetID + '/transfer_links/' + transferID;
      } else {
        url += id;
      }
      $.ajax({
        url,
        type: 'PATCH',
        headers: {
          'x-csrf-token': csrfToken,
        },
        data
      });
    },
    disable: [
      function (date) {
        // return true to disable, should enable today
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return date < yesterday;
      }
    ],
  });
});
