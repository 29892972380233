import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["results", "form"];

  search() {
    Rails.fire(this.formTarget, 'submit');
  }

  handleSearchResults(event) {
    const [data, status, xhr] = event.detail

    this.resultsTarget.innerHTML = xhr.response
  }
}
