import $ from 'jquery';
import flatpickr from 'flatpickr';

$(document).on('turbolinks:load', function () {
  // flatpickr
  flatpickr('#dashboard-search-time', {
    dateFormat: "d M Y",
    mode: "range",
    defaultDate: $("#dashboard-search-time")[0]?.defaultValue
  });
});
