import $ from 'jquery';
require("dropzone");

const metaCsrf = document.querySelector("meta[name='csrf-token']");
const csrfToken = metaCsrf.getAttribute('content');

$(document).on('turbolinks:load', function () {
  // stop close popup when click inside popup content
  $(".stimulus").on("click", ".content-share-to-member-modal", function (e) {
    e.stopPropagation();
    return;
  });

  // close share to member modal
  $(".stimulus").on("click", ".bg-share-to-member-modal", function (e) {
    $('.share-to-member-modal').hide();
  });

  // click done button
  $(".stimulus").on("click", ".share-to-member-modal-done", function (e) {
    $('.share-to-member-modal').hide();
  });

  let isPressEnter = false;
  // add member
  $(".stimulus").on("keydown", ".form-share-asset", function (e) {
    if (e.key == "Enter" && !isPressEnter) {
      const email = e.target.value;
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!validRegex.test(email)) {
        $("#email-error").show();
        return;
      }
      isPressEnter = true;
      $("#email-error").hide();

      const element_id = e.target.id;
      const prefix = "form-share-asset-";
      const assetID = element_id.slice(element_id.indexOf(prefix) + prefix.length);
      const data = {
        email,
        asset_id: assetID
      };
      $.ajax({
        url: `/asset_permissions`,
        type: 'POST',
        headers: {
          'x-csrf-token': csrfToken,
        },
        data,
        success: function () {
          window.location.reload();
        }
      });
    }
  });

  // Change select permission
  $(".stimulus").on("change", ".select-asset-permission", function (e) {
    const element_id = e.target.id;
    const prefix = "select-asset-permission-";
    const permissionID = element_id.slice(element_id.indexOf(prefix) + prefix.length);
    const data = {
      permission: e.target.value
    };

    $.ajax({
      url: `/asset_permissions/${permissionID}`,
      type: 'PUT',
      headers: {
        'x-csrf-token': csrfToken,
      },
      data: data
    });
  });

  // click remvoe asset permission button
  $(".stimulus").on("click", ".remove-asset-permission", function (e) {
    const element_id = e.target.id;
    const prefix = "remove-asset-permission-";
    const permissionID = element_id.slice(element_id.indexOf(prefix) + prefix.length);

    $("#confirm-asset-title").html('Remove this user from the asset?');
    $(".confirm-asset-confirm").html('YES, REMOVE NOW.');
    $("#confirm-asset").val(`permission_${permissionID}`);
    $("#confirm-asset").show();
  });
});
