import $, { ready } from 'jquery'

$(document).on('turbolinks:load', function () {
  const metaCsrf = document.querySelector("meta[name='csrf-token']");
  const csrfToken = metaCsrf.getAttribute('content');

  // show manage member modal after invite new member
  if (window.location.search.includes('invited')) {
    $("#members-modal").show();
  }
  // click manage member button
  $("#manage-member").on("click", function (e) {
    e.stopPropagation();
    $("#members-modal").show();
  });

  let isPressEnter = false;
  // add member
  $("#form-add-member").on("keydown", function (e) {
    if (e.key == "Enter" && !isPressEnter) {
      const email = e.target.value;
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!validRegex.test(email)) {
        $("#email-error").show();
        e.preventDefault();
        return;
      }
      isPressEnter = true;
      $("#email-error").hide();

      const groupIDWithQuery = document.URL.split("/").pop();
      const groupID = groupIDWithQuery.split("?")[0];
      const data = {
        id: groupID,
        email
      };
      $.ajax({
        url: `/members`,
        type: 'POST',
        headers: {
          'x-csrf-token': csrfToken,
        },
        data,
        success: function () {
          if (!window.location.search.includes('invited')) {
            window.location.search += '&invited';
          }
          else {
            window.location.reload();
          }
        }
      });
      e.preventDefault();
    }
  })

  // Change select permission
  $(".select-user").on("change", function (e) {
    const element_id = e.target.id;
    const prefixGroup = "select-group-";
    const prefixUser = "-user-";
    const groupID = element_id.slice(element_id.indexOf(prefixGroup) + prefixGroup.length, element_id.indexOf(prefixUser))
    const userID = element_id.slice(element_id.indexOf(prefixUser) + prefixUser.length)

    const data = {
      user_id: userID,
      permission: e.target.value
    };

    $.ajax({
      url: `/members/${groupID}`,
      type: 'PUT',
      headers: {
        'x-csrf-token': csrfToken,
      },
      data: data
    });
  });

  // click member modal done button
  $("#members-modal-done").on("click", function (e) {
    $("#members-modal").hide();
  });

  // stop close modal when click inside modal content
  $(".content-members-modal").on("click", function (e) {
    e.stopPropagation();
    return;
  });

  // click background
  $(".bg-members-modal").on("click", function (e) {
    $("#members-modal").hide();
  });

  // click permanent delete button
  $(".member-delete").on("click", function (e) {
    const element_id = e.target.id;
    const prefix = "member-delete-";
    const userID = element_id.slice(element_id.indexOf(prefix) + prefix.length);
    $("#member-confirm-title").html('Remove this member?');
    $(".member-confirm-confirm").html('YES, REMOVE NOW.');
    $("#confirm-modal").val(userID);
    $("#confirm-modal").show();
  });

  // click discard button
  $("#member-confirm-discard").on("click", function (e) {
    $("#confirm-modal").hide();
  });

  // click confirm button
  $(".member-confirm-confirm").on("click", function (e) {
    e.stopPropagation();
    const groupID = document.URL.split("/").pop();
    const userID = $("#confirm-modal").val();

    const data = {
      user_id: userID
    };

    $.ajax({
      url: `/members/${groupID}`,
      type: 'DELETE',
      headers: {
        'x-csrf-token': csrfToken,
      },
      data,
      success: function () {
        $("#confirm-modal").hide();
        $("#complete-modal").show();
      }
    });
  });

  // click background
  $(".bg-member-confirm-modal").on("click", function (e) {
    $("#confirm-modal").hide();
  });

  $(".left-group").on("click", function () {
    const elementID = this.id;
    const prefix = "left-group-";
    const groupID = elementID.slice(elementID.indexOf(prefix) + prefix.length);
    $("#left-group-confirm-title").html('Left this group?');
    $(".left-group-confirm-confirm").html('YES, LEFT NOW.');
    $("#left-group-confirm-modal").val(groupID);
    $("#left-group-confirm-modal").show();
  });

  // click discard button
  $("#left-group-confirm-discard").on("click", function (e) {
    $("#left-group-confirm-modal").hide();
  });

  // click confirm button
  $(".left-group-confirm-confirm").on("click", function (e) {
    e.stopPropagation();
    const groupID = $("#left-group-confirm-modal").val();

    $.ajax({
      url: `/members/${groupID}/left_group`,
      type: 'DELETE',
      headers: {
        'x-csrf-token': csrfToken,
      }
    });
  });

  // click background
  $(".bg-left-group-confirm-modal").on("click", function (e) {
    $("#left-group-confirm-modal").hide();
  });

  // stop close modal when click inside left group content
  $(".content-left-group-confirm-modal").on("click", function (e) {
    e.stopPropagation();
    return;
  });
});
