import $ from 'jquery';

$(document).on('turbolinks:load', function () {
  // check edit button is click or not
  let clickedEdit = false;

  // show panel when hover the note-area
  $(".note-area").on("mouseenter", function(e) {
    if (clickedEdit) {
      return false;
    }
    const target_id = e.target.id
    const note_id_index = target_id.lastIndexOf('-') + 1
    if ($('#edit-form-' + target_id.slice(note_id_index)).css('display') == 'none') {
      $("#panel-" + target_id).show();
    }
  })

  // hide panel when not hover the note-area
  $(".note-area").on("mouseleave", function(e) {
    if (clickedEdit) {
      return false;
    }
    $("#panel-" + e.target.id).hide();
  })

  // show edit and hide note when click edit note
  $(".edit").on("click", function(e) {
    clickedEdit = true;
    const target_id = e.target.id;
    $("#note-" + target_id).hide();
    $("#edit-form-" + target_id).show();
    $("#panel-note-area-" + target_id).hide();
  });

  // hide edit and show note when click cancel
  $(".cancel").on("click", function(e) {
    clickedEdit = false;
    const target_id = e.target.id;
    $("#edit-form-" + target_id).hide();
    $("#note-" + target_id).show();
    $("#panel-note-area-" + target_id).show();
  });

  // open modal
  $(".delete-note").on("click", function(e) {
    $(".delete-note-modal").show();
  })

  // stop close modal when click inside modal content
  $(".content-delete-note-modal").on("click", function (e) {
    e.stopPropagation();
    return;
  });

  // close modal
  $(".bg-delete-note-modal, .cancel-delete-note").on("click", function (e) {
    $(".delete-note-modal").hide();
  });

  // enter password before access folder
  $('.delete-note-ajax').on("click", function(e) {
    const metaCsrf = document.querySelector("meta[name='csrf-token']");
    const csrfToken = metaCsrf.getAttribute('content');
    
    const asset_id = this.id.slice(this.id.indexOf('-') + 1, this.id.indexOf('_'));
    const note_id = this.id.slice(this.id.lastIndexOf('-') + 1);

    $.ajax({
      url: `/assets/${asset_id}/notes/${note_id}`,
      type: 'DELETE',
      headers: {
        'x-csrf-token': csrfToken,
        'content-type': 'application/json',
        'accept': 'application/json'
      }
    });
  })
});
