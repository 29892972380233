// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "../layouts/application.css";
import "channels";
import "controllers";

require("packs/groups");
require("packs/assets");
require("packs/notes");
require("packs/accessories");
require("packs/trashs");
require("packs/members");
require("packs/transfer_links");
require("packs/users");
require("packs/password");
require("packs/asset_permissions");
require("packs/dashboard");

window.Rails = Rails
Rails.start()
Turbolinks.start()
ActiveStorage.start()
