import $ from 'jquery';
import flatpickr from 'flatpickr';

$(document).on('turbolinks:load', function () {
  const metaCsrf = document.querySelector("meta[name='csrf-token']");
  const csrfToken = metaCsrf.getAttribute('content');

  function clickTrashRecovery(e) {
    $(".popup-action").hide();
    $("#trash-confirm-title").html('Recovery this file?');
    $(".trash-confirm-confirm").html('YES, I’LL RECOVERY.');
    $("#complete-title").html('Success Recovery!');
    $("#confirm-modal").val([e.target.id, 'recovery']);
    $("#confirm-modal").show();
    $("#expiry-date-error").hide();
  }

  // click recovery button
  $(".stimulus").on("click", ".trash-recovery-without-expiry-date", function (e) {
    $("#recovery-update-date").hide();
    clickTrashRecovery(e);
  });

  // click recovery button
  $(".stimulus").on("click", ".trash-recovery-with-expiry-date", function (e) {
    $("#recovery-update-date").show();
    clickTrashRecovery(e);
  });

  // click permanent delete button
  $(".stimulus").on("click", ".trash-delete", function (e) {
    $(".popup-action").hide();
    $("#trash-confirm-title").html('Delete Permanent?');
    $(".trash-confirm-confirm").html('YES, DELETE NOW.');
    $("#recovery-update-date").hide();
    $("#complete-title").html('Success Delete');
    $("#confirm-modal").val([e.target.id, 'delete']);
    $("#confirm-modal").show();
    $("#expiry-date-error").hide();
  });

  // click confirm modal backgronud
  $(".bg-popup-action-trash").on("click", function (e) {
    $(".popup-action").hide();
  });

  // click confirm modal discard button
  $("#trash-confirm-discard").on("click", function (e) {
    $("#confirm-modal").hide();
  });

  // click confirm modal confirm(recovery/permanent delete) button
  $(".trash-confirm-confirm").on("click", function (e) {
    e.stopPropagation();
    const asset_id = $("#confirm-modal").val()[0];
    const method = $("#confirm-modal").val()[1];
    const data = {
      expiry_date: $("#recover-expiry-date").val()
    };
    $.ajax({
      url: `/assets/trashs/${asset_id}`,
      type: method == 'recovery' ? 'PUT' : 'DELETE',
      headers: {
        'x-csrf-token': csrfToken,
      },
      data,
      success: function () {
        $("#confirm-modal").hide();
        $("#complete-modal").show();
        $("#expiry-date-error").hide();
      },
      error: function () {
        $("#expiry-date-error").show();
      }
    });
  });

  // click confirm modal content
  $(".content-trash-confirm-modal").on("click", function (e) {
    e.stopPropagation();
    return;
  });

  // click confirm modal background
  $(".bg-trash-confirm-modal").on("click", function (e) {
    $("#confirm-modal").hide();
  });

  // flatpickr
  flatpickr("#recover-expiry-date", {
    enableTime: true,
    dateFormat: "d/m/Y h:i K",
    defaultDate: new Date().fp_incr(7),
    onClose: function (selectedDates, dateStr, instance) {
      $("#recover-expiry-date").val(selectedDates[0]);
    },
    disable: [
      function (date) {
        // return true to disable, should enable today
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return date < yesterday;

      }
    ],
  });
});
